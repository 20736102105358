<template>
  <v-row>
    <loading :active.sync="loading"></loading>
    <v-card class="pa-6 mt-n8" width="100%" flat>
      <v-container fluid>
        <v-row>
          <v-col cols="12" md="7" sm="7">
            <v-btn icon class="mt-n3" @click="cancel()">
              <v-icon> mdi-less-than </v-icon>
            </v-btn>
            <span class="text-h4">
              Untitled - {{ convertDate(this.CourseData.updatedAt) }}
            </span>
          </v-col>
          <v-col cols="12" md="2" sm="2" class="text-right mt-n1">
            <v-select
              v-model="status"
              outlined
              :items="statusItems"
              item-value="name"
              background-color="white"
              style="border-radius: 0px"
              disabled
            >
              <template v-slot:selection="{ item }">
                <span v-if="item.name == 'เปิดใช้งาน'" style="color: #11c872">
                  <v-icon style="color: #11c872"> mdi-circle </v-icon>
                  <v-icon style="color: #11c872"> mdi-blank </v-icon>
                  {{ item.name }}
                </span>
                <span v-if="item.name == 'ปิดใช้งาน'" style="color: red">
                  <v-icon style="color: red"> mdi-circle </v-icon>
                  <v-icon style="color: red"> mdi-blank </v-icon>
                  {{ item.name }}
                </span>
                <span v-if="item.name == 'ฉบับร่าง'" style="color: #026aa2">
                  <v-icon style="color: #026aa2"> mdi-circle </v-icon>
                  <v-icon style="color: #026aa2"> mdi-blank </v-icon>
                  {{ item.name }}
                </span>
              </template>
              <template v-slot:item="{ item }">
                <span v-if="item.name == 'เปิดใช้งาน'" style="color: #11c872">
                  <v-icon style="color: #11c872"> mdi-circle </v-icon>
                  <v-icon style="color: #11c872"> mdi-blank </v-icon>
                  {{ item.name }}
                </span>
                <span v-if="item.name == 'ปิดใช้งาน'" style="color: red">
                  <v-icon style="color: red"> mdi-circle </v-icon>
                  <v-icon style="color: red"> mdi-blank </v-icon>
                  {{ item.name }}
                </span>
                <span v-if="item.name == 'ฉบับร่าง'" style="color: #026aa2">
                  <v-icon style="color: #026aa2"> mdi-circle </v-icon>
                  <v-icon style="color: #026aa2"> mdi-blank </v-icon>
                  {{ item.name }}
                </span>
              </template>
            </v-select>
          </v-col>
          <v-divider class="mt-2" vertical style="height: 56px"></v-divider>
          <v-col cols="12" md="1" sm="1" class="text-right">
            <v-btn
              @click="cancel()"
              text
              color="#2ab3a3"
              style="
                border: solid 1px #2ab3a3;
                border-radius: 0px;
                padding: 12px 16px;
                gap: 8px;
                width: 48px;
                height: 48px;
              "
            >
              <v-icon large> mdi-eye-outline</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="12" md="2" sm="2">
            <v-btn
              @click="submitAll()"
              color="#2AB3A3"
              disabled
              elevation="0"
              style="
                border-radius: 0px;
                padding: 12px 16px;
                gap: 8px;
                width: 185px;
                height: 48px;
              "
            >
              ยืนยันการแก้ไขทั้งหมด
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <v-tabs centered v-model="e1" grow>
      <v-row>
        <v-tab class="text-h6"> เกี่ยวกับหลักสูตร </v-tab>
        <v-tab :disabled="!courseId" class="text-h6"> รายวิชา </v-tab>
        <v-tab :disabled="!courseId" class="text-h6">
          สาขาเอกและรูปแบบหลักสูตร
        </v-tab>
        <v-tab :disabled="!courseId" class="text-h6"> โครงสร้างหลักสูตร </v-tab>
        <v-tab :disabled="!courseId" class="text-h6"> ค่าเล่าเรียน </v-tab>
        <v-tab :disabled="!courseId" class="text-h6">
          รายละเอียดเพิ่มเติม
        </v-tab>
      </v-row>
      <!-- <v-tab class="text-h6"> เกี่ยวกับหลักสูตร </v-tab>
      <v-tab :disabled="!courseId" class="text-h6"> รายวิชา </v-tab>
      <v-tab :disabled="!courseId" class="text-h6">
        สาขาเอกและรูปแบบหลักสูตร
      </v-tab>
      <v-tab :disabled="!courseId" class="text-h6"> โครงสร้างหลักสูตร </v-tab>
      <v-tab :disabled="!courseId" class="text-h6"> ค่าเล่าเรียน </v-tab>
      <v-tab :disabled="!courseId" class="text-h6"> รายละเอียดเพิ่มเติม </v-tab> -->

      <v-tab-item>
        <v-card color="#f5f5f5" class="pa-6">
          <v-row>
            <v-col cols="12" md="10" sm="10" class="mt-10 ma-5">
              <span
                style="
                  font-family: 'IBM Plex Sans Thai';
                  font-style: normal;
                  font-weight: 600;
                  font-size: 24px;
                  line-height: 40px;
                "
              >
                เกี่ยวกับหลักสูตร
              </span>
            </v-col>
          </v-row>
          <v-form
            ref="createForm"
            v-model="checkValidate"
            :lazy-validation="lazy"
          >
            <v-card class="pa-6 mt-n8" width="100%">
              <v-row>
                <v-col cols="12" md="6" sm="6"
                  ><span class="pt-2">ชื่อหลักสูตร (TH)</span>
                  <span style="color: red">*</span></v-col
                >
                <v-col cols="12" md="6" sm="6"
                  ><span class="pt-2">ชื่อหลักสูตร (EN)</span>
                  <span style="color: red">*</span></v-col
                >
                <v-col cols="12" md="6" sm="6">
                  <v-text-field
                    hide-details
                    v-model="form1.title_th"
                    placeholder="ระบุชื่อหลักสูตร (TH)"
                    dense
                    outlined
                    :rules="rules.required"
                    disabled
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="6" sm="6">
                  <v-text-field
                    hide-details
                    v-model="form1.title_en"
                    placeholder="ระบุชื่อหลักสูตร (EN)"
                    dense
                    outlined
                    :rules="rules.required"
                    disabled
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="6" sm="6"
                  ><span class="pt-2">คำอธิบายภาพรวมหลักสูตร (TH)</span>
                  <span style="color: red">*</span></v-col
                >
                <v-col cols="12" md="6" sm="6"
                  ><span class="pt-2">คำอธิบายภาพรวมหลักสูตร (EN)</span>
                  <span style="color: red">*</span></v-col
                >
                <v-col cols="12" md="6" sm="6">
                  <v-textarea
                    v-model="form1.short_desc_th"
                    placeholder="ระบุคำอธิบายภาพรวมหลักสูตร (TH)"
                    dense
                    outlined
                    :rules="rules.required"
                    disabled
                  ></v-textarea>
                </v-col>
                <v-col cols="12" md="6" sm="6">
                  <v-textarea
                    v-model="form1.short_desc_en"
                    placeholder="ระบุคำอธิบายภาพรวมหลักสูตร (EN)"
                    dense
                    outlined
                    :rules="rules.required"
                    disabled
                  ></v-textarea>
                </v-col>

                <v-col cols="12" md="6" sm="6"
                  ><span class="pt-2">จุดเด่นของหลักสูตร (TH)</span>
                </v-col>
                <v-col cols="12" md="6" sm="6"
                  ><span class="pt-2">จุดเด่นของหลักสูตร (EN)</span>
                </v-col>
                <v-col cols="12" md="6" sm="6">
                  <quill-editor
                    v-model="form1.course_highlights_th"
                    :content="content"
                    :options="editorOption"
                    @change="onEditorChange($event)"
                    disabled
                  />
                </v-col>

                <v-col cols="12" md="6" sm="6">
                  <quill-editor
                    v-model="form1.course_highlights_en"
                    :content="content1"
                    :options="editorOption"
                    @change="onEditorChange1($event)"
                    disabled
                  />
                </v-col>
              </v-row>
            </v-card>

            <v-col class="ma-8">
              <span
                style="
                  font-family: 'IBM Plex Sans Thai';
                  font-style: normal;
                  font-weight: 600;
                  font-size: 24px;
                  line-height: 40px;
                "
              >
                รูปหลักสูตร
              </span>
            </v-col>
            <v-card class="pa-6 mt-n8" width="100%">
              <v-row>
                <v-col cols="12" md="12" sm="12" xs="12">
                  <v-card outlined>
                    <v-row no-gutters align="center">
                      <v-col
                        cols="12"
                        md="11"
                        sm="11"
                        xs="11"
                        class="ma-4"
                        id="step-3"
                      >
                        <span>รูปหลักสูตร</span>
                        <br />
                        <span>ขนาดรูปที่แนะนำ : 1000 x 550px</span>

                        <!-- <br />
                        <span style="color: red">อัพโหลดรูปภาพขนาด 500x250px</span>
                        <br /> -->
                        <v-img :src="form1.showImage" max-width="auto"></v-img>

                        <v-col cols="12" md="12" class="text-right">
                          <input
                            type="file"
                            accept="image/*"
                            id="file"
                            ref="q_1_1"
                            v-on:change="submitfileMain('q_1_1')"
                            disabled
                          />
                        </v-col>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-col>
                <!-- <v-col cols="12" md="12" sm="12" class="text-right">
                    <v-btn @click="submitfileMain('q_1_1')" color="teal lighten-4"
                      >อัพโหลดรูปหลักสูตร</v-btn
                    >
                  </v-col> -->
              </v-row>
            </v-card>

            <v-col class="ma-8">
              <span
                style="
                  font-family: 'IBM Plex Sans Thai';
                  font-style: normal;
                  font-weight: 600;
                  font-size: 24px;
                  line-height: 40px;
                "
              >
                รูป Background Banner
              </span>
            </v-col>
            <v-card class="pa-6 mt-n8" width="100%">
              <v-row>
                <v-col cols="12" md="12" sm="12" xs="12">
                  <v-card outlined>
                    <v-row no-gutters align="center">
                      <v-col
                        cols="12"
                        md="11"
                        sm="11"
                        xs="11"
                        class="ma-4"
                        id="step-3"
                      >
                        <span>รูป Background Banner</span>
                        <br />
                        <span>ขนาดรูปที่แนะนำ : 2000 x 680px</span>

                        <!-- <br />
                          <span style="color: red">อัพโหลดรูปภาพขนาด 500x250px</span>
                          <br /> -->
                        <v-img :src="form1.showImage1" max-width="auto"></v-img>

                        <v-col cols="12" md="12" class="text-right">
                          <input
                            type="file"
                            accept="image/*"
                            id="file"
                            ref="q_1_2"
                            v-on:change="submitfileMain('q_1_2')"
                            disabled
                          />
                        </v-col>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-col>
                <!-- <v-col cols="12" md="12" sm="12" class="text-right">
                      <v-btn @click="submitfileMain('q_1_2')" color="teal lighten-4"
                        >อัพโหลดรูป Banner</v-btn
                      >
                    </v-col> -->
              </v-row>
            </v-card>

            <!-- <v-row align="center" justify="end">
                <v-btn @click="cancel()" class="mr-4">กลับ</v-btn>
                <v-btn @click="submit('q_1_1')" dark color="#2AB3A3">บันทึก</v-btn>
              </v-row> -->
          </v-form>
          <!-- <v-col cols="12" md="12" sm="12" class="text-right">
            <v-btn @click="cancel()"> กลับ </v-btn>
            &nbsp;
            <v-btn color="#2AB3A3" dark @click="e1 = 1"> ถัดไป </v-btn>
          </v-col> -->
        </v-card>
      </v-tab-item>

      <v-tab-item>
        <v-card color="#f5f5f5" class="pa-6">
          <v-row>
            <v-col cols="12" md="10" sm="10" class="mt-10 ml-5">
              <span
                style="
                  font-family: 'IBM Plex Sans Thai';
                  font-style: normal;
                  font-weight: 600;
                  font-size: 24px;
                  line-height: 40px;
                "
              >
                รายวิชา
              </span>
            </v-col>
            <v-col cols="12" md="2" sm="2" class="mt-10 ml-n5">
              <!-- <DialogMajor
                :courseId="courseId"
                @getMajorSub="getMajorSub"
              /> -->
              <DialogMajorEdit
                :openDialogMajorEdit="openDialogMajorEdit"
                :courseId="courseId"
                :itemDialogMajorEdit="itemDialogMajorEdit"
                @getMajorSub="getMajorSub"
                @closeDialogMajorEdit="closeDialogMajorEdit"
              />
            </v-col>
          </v-row>
          <v-card class="pa-6" width="100%">
            <v-col cols="12">
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    hide-details
                    class="mb-4"
                    v-model="searchMajorSub"
                    dense
                    outlined
                    placeholder="ค้นหา"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-autocomplete
                    hide-details
                    v-model="filterMajor"
                    :items="subItems"
                    item-text="text"
                    dense
                    outlined
                    @change="filterSubjectGroup(filterMajor)"
                  ></v-autocomplete>
                </v-col>
                <v-spacer></v-spacer>
                <v-col cols="12">
                  <!-- <DialogMajor
                    :courseId="courseId"
                    @getMajorSub="getMajorSub"
                  />
                  <DialogMajorEdit
                    :openDialogMajorEdit="openDialogMajorEdit"
                    :courseId="courseId"
                    :itemDialogMajorEdit="itemDialogMajorEdit"
                    @getMajorSub="getMajorSub"
                    @closeDialogMajorEdit="closeDialogMajorEdit"
                  /> -->

                  <!-- <v-btn @click="goToCreateMajorSub()" color="#2AB3A3" dark
                  >เพิ่มรายวิชาใหม่</v-btn
                > -->
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12">
              <v-card
                ><v-data-table
                  :headers="headersMajorSub"
                  :items="itemsMajorSub"
                  :search="searchMajorSub"
                  :items-per-page="5"
                  class="elevation-1"
                >
                  <!-- <template v-slot:[`item.action`]="{ item }">
                    <v-icon @click="UpdateMajorSub(item)">mdi-pencil</v-icon>
                    <v-icon @click="ViewMajorSub(item)">mdi-content-copy</v-icon>
                    <v-icon @click="DeleteItemMajorSub(item)"
                      >mdi-delete</v-icon
                    >
                  </template> -->
                </v-data-table>
              </v-card>
            </v-col>
          </v-card>
          <!-- <v-col cols="12" md="12" sm="12" class="text-right">
            <v-btn @click="e1 = 0"> Back </v-btn>
            &nbsp;
            <v-btn color="#2AB3A3" dark @click="e1 = 2"> ถัดไป </v-btn>
          </v-col> -->
        </v-card>
      </v-tab-item>

      <v-tab-item>
        <v-card color="#f5f5f5" class="pa-6">
          <v-row>
            <v-col cols="12" md="10" sm="10" class="mt-10 ml-5">
              <span
                style="
                  font-family: 'IBM Plex Sans Thai';
                  font-style: normal;
                  font-weight: 600;
                  font-size: 24px;
                  line-height: 40px;
                "
              >
                สาขาเอกถ้ามี
              </span>
            </v-col>
            <v-col cols="12" md="2" sm="2" class="mt-10 ml-n5 text-right">
              <v-btn disabled @click="addMajor()" color="#2AB3A3" dark
                >(+) เพิ่มสาขาเอก</v-btn
              >
            </v-col>
          </v-row>

          <!-- <v-card class="pa-6" width="100%">
            <v-data-table
              :headers="headersMajorSub"
              :items="itemsMajorSub"
              :search="searchMajorSub"
              :items-per-page="5"
              class="elevation-1"
            >
              <template v-slot:[`item.action`]="{ item }">
                <v-icon @click="UpdateMajorSub(item)">mdi-pencil</v-icon>
                <v-icon @click="ViewMajorSub(item)">mdi-content-copy</v-icon>
                <v-icon @click="DeleteItemMajorSub(item)">mdi-delete</v-icon>
              </template>
            </v-data-table>
          </v-card> -->

          <v-card class="pa-6" width="100%">
            <!-- <v-col cols="12">
              <v-checkbox
                v-model="checkBox1"
                placeholder="สาขาเอก (ถ้ามี)"
              ></v-checkbox>
            </v-col> -->
            <v-row v-if="!form3.majors.length">
              <v-col cols="12" md="12" sm="12" class="text-center">
                <span
                  ><b><span>ไม่มีข้อมูลสาขาเอก</span> </b>
                </span>
              </v-col>
            </v-row>
            <v-row>
              <v-card
                class="pa-6 mt-5"
                width="100%"
                v-for="(itemMajor, index) in form3.majors"
                :key="index"
              >
                <v-row>
                  <v-col cols="12" md="2" sm="2">
                    <span class="pt-2 text-h6"
                      >เพิ่มสาขาเอก #{{ index + 1 }}</span
                    >
                  </v-col>
                  <v-col cols="12" md="10" sm="10" class="text-right">
                    <v-btn disabled @click="deleteMajor(index)" color="red" dark
                      >(-) ลบสาขาเอก #{{ index + 1 }}</v-btn
                    >
                  </v-col>

                  <v-col cols="12" md="6" sm="6">
                    <span class="pt-2">ชื่อสาขาเอก (TH)</span>
                  </v-col>
                  <v-col cols="12" md="6" sm="6">
                    <span class="pt-2">ชื่อสาขาเอก (EN)</span>
                  </v-col>
                  <v-col cols="12" md="6" sm="6">
                    <v-text-field
                      hide-details
                      v-model="itemMajor.name_th"
                      placeholder="ระบุชื่อสาขาเอก (TH)"
                      dense
                      outlined
                      disabled
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" sm="6">
                    <v-text-field
                      hide-details
                      v-model="itemMajor.name_en"
                      placeholder="ระบุชื่อสาขาเอก (EN)"
                      dense
                      outlined
                      disabled
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" sm="6">
                    <span class="pt-2">หมายเหตุ (TH)</span>
                  </v-col>
                  <v-col cols="12" md="6" sm="6">
                    <span class="pt-2">หมายเหตุ (EN)</span>
                  </v-col>
                  <v-col cols="12" md="6" sm="6">
                    <v-text-field
                      hide-details
                      v-model="itemMajor.desc_th"
                      placeholder="ระบุหมายเหตุ (TH)"
                      dense
                      outlined
                      disabled
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" sm="6">
                    <v-text-field
                      hide-details
                      v-model="itemMajor.desc_en"
                      placeholder="ระบุหมายเหตุ (EN)"
                      dense
                      outlined
                      disabled
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" md="12" sm="12">
                    <span class="pt-2 text-h6">รายวิชา</span>
                  </v-col>

                  <v-col cols="12" md="7" sm="7">
                    <v-row
                      v-for="(element, index1) in itemMajor.subject"
                      :key="index1"
                    >
                      <v-col cols="12" md="10" sm="10">
                        <v-autocomplete
                          hide-details
                          v-model="element.id"
                          placeholder="ระบุรายวิชา"
                          :items="itemsMajorSubStable"
                          item-value="id"
                          dense
                          outlined
                          disabled
                        >
                          <template v-slot:selection="{ item }">
                            {{ item.name_th }} - {{ item.name_en }}
                          </template>
                          <template v-slot:item="{ item }">
                            {{ item.name_th }} - {{ item.name_en }}
                          </template>
                        </v-autocomplete>
                      </v-col>
                      <v-col
                        cols="12"
                        md="2"
                        sm="2"
                        v-if="itemMajor.subject.length != 1"
                      >
                        <v-btn disabled @click="deleteSubject(index, index1)"
                          >ลบ</v-btn
                        >
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12" md="2" sm="2"> </v-col>
                  <v-col cols="12" md="12" sm="12">
                    <v-btn
                      disabled
                      @click="addSubject(index)"
                      color="#2AB3A3"
                      dark
                      >(+) เพิ่มรายวิชา</v-btn
                    >
                  </v-col>

                  <v-col cols="12" md="6" sm="6">
                    <span class="pt-2">โอกาสในการประกอบอาชีพ (TH)</span>
                  </v-col>
                  <v-col cols="12" md="6" sm="6">
                    <span class="pt-2">โอกาสในการประกอบอาชีพ (EN)</span>
                  </v-col>
                  <v-col cols="12" md="6" sm="6">
                    <quill-editor
                      v-model="itemMajor.job_opportunities"
                      :content="itemMajor.job_opportunities"
                      :options="editorOption"
                      @change="onEditorChange2($event)"
                      disabled
                    />
                  </v-col>
                  <v-col cols="12" md="6" sm="6">
                    <quill-editor
                      v-model="itemMajor.job_opportunities_en"
                      :content="itemMajor.job_opportunities_en"
                      :options="editorOption"
                      @change="onEditorChange5($event)"
                      disabled
                    />
                  </v-col>
                </v-row>
              </v-card>
            </v-row>
          </v-card>

          <v-row>
            <v-col cols="12" md="10" sm="10" class="mt-10 ml-5">
              <span
                style="
                  font-family: 'IBM Plex Sans Thai';
                  font-style: normal;
                  font-weight: 600;
                  font-size: 24px;
                  line-height: 40px;
                "
              >
                รูปแบบหลักสูตรถ้ามี
              </span>
            </v-col>
            <v-col cols="12" md="2" sm="2" class="mt-10 ml-n5 text-right">
              <v-btn disabled @click="addTypeCourse()" color="#2AB3A3" dark
                >(+) เพิ่มรูปแบบหลักสูตร</v-btn
              >
            </v-col>
          </v-row>

          <v-card class="pa-6" width="100%">
            <!-- <v-col cols="12">
              <v-checkbox
                v-model="checkBox2"
                placeholder="รูปแบบหลักสูตร (ถ้ามี)"
              ></v-checkbox>
            </v-col> -->
            <v-row v-if="!form3.typeCourse.length">
              <v-col cols="12" md="12" sm="12" class="text-center">
                <span
                  ><b><span>ไม่มีข้อมูลรูปแบบหลักสูตร</span> </b>
                </span>
              </v-col>
            </v-row>
            <v-row>
              <v-card
                class="pa-6 mt-5"
                width="100%"
                v-for="(itemTypeCourse, index) in form3.typeCourse"
                :key="index"
              >
                <v-row>
                  <v-col cols="12" md="2" sm="2">
                    <span class="pt-2 text-h6"
                      >รูปแบบหลักสูตร #{{ index + 1 }}</span
                    >
                  </v-col>
                  <v-col cols="12" md="10" sm="10" class="text-right">
                    <!-- <v-btn  @click="deleteTypeCourse(index)" color="red" dark
                      >(-) ลบรูปแบบหลักสูตร #{{ index + 1 }}</v-btn
                    > -->
                  </v-col>
                  <v-col cols="12" md="6" sm="6">
                    <span class="pt-2">ชื่อรูปแบบหลักสูตร (TH)</span>
                  </v-col>
                  <v-col cols="12" md="6" sm="6">
                    <span class="pt-2">ชื่อรูปแบบหลักสูตร (EN)</span>
                  </v-col>
                  <v-col cols="12" md="6" sm="6">
                    <v-text-field
                      hide-details
                      v-model="itemTypeCourse.name_th"
                      placeholder="ระบุชื่อรูปแบบหลักสูตร (TH)"
                      dense
                      outlined
                      disabled
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" sm="6">
                    <v-text-field
                      hide-details
                      v-model="itemTypeCourse.name_en"
                      placeholder="ระบุชื่อรูปแบบหลักสูตร (EN)"
                      dense
                      outlined
                      disabled
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" sm="6">
                    <span class="pt-2">คำอธิบายรูปแบบ (TH)</span>
                  </v-col>
                  <v-col cols="12" md="6" sm="6">
                    <span class="pt-2">คำอธิบายรูปแบบ (EN)</span>
                  </v-col>
                  <v-col cols="12" md="6" sm="6">
                    <v-text-field
                      hide-details
                      v-model="itemTypeCourse.desc_th"
                      placeholder="ระบุคำอธิบายรูปแบบ (TH)"
                      dense
                      outlined
                      disabled
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" sm="6">
                    <v-text-field
                      hide-details
                      v-model="itemTypeCourse.desc_en"
                      placeholder="ระบุคำอธิบายรูปแบบ (EN)"
                      dense
                      outlined
                      disabled
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" md="12" sm="12">
                    <span class="pt-2 text-h6">รายวิชา</span>
                  </v-col>

                  <v-col cols="12" md="7" sm="7">
                    <v-row
                      v-for="(element, index1) in itemTypeCourse.subject"
                      :key="index1"
                    >
                      <v-col cols="12" md="10" sm="10">
                        <v-autocomplete
                          hide-details
                          v-model="element.id"
                          placeholder="ระบุรายวิชา"
                          :items="itemsMajorSubStable"
                          item-value="id"
                          dense
                          outlined
                          disabled
                        >
                          <template v-slot:selection="{ item }">
                            {{ item.name_th }} - {{ item.name_en }}
                          </template>
                          <template v-slot:item="{ item }">
                            {{ item.name_th }} - {{ item.name_en }}
                          </template>
                        </v-autocomplete>
                      </v-col>
                      <v-col
                        cols="12"
                        md="2"
                        sm="2"
                        v-if="itemTypeCourse.subject.length != 1"
                      >
                        <v-btn @click="deleteSubject1(index, index1)">ลบ</v-btn>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12" md="12" sm="12">
                    <v-btn
                      disabled
                      @click="addSubject1(index)"
                      color="#2AB3A3"
                      dark
                      >(+) เพิ่มรายวิชา</v-btn
                    >
                  </v-col>
                </v-row>
              </v-card>
            </v-row>
          </v-card>
          <!-- <v-col cols="12" md="12" sm="12" class="text-right">
            <v-btn @click="e1 = 1"> Back </v-btn>
            &nbsp;
            <v-btn color="#2AB3A3" dark @click="e1 = 3"> ถัดไป </v-btn>
          </v-col> -->
        </v-card>
      </v-tab-item>

      <v-tab-item>
        <v-card color="#f5f5f5" class="pa-6">
          <v-row>
            <v-col cols="12" md="10" sm="10" class="mt-10 ml-5">
              <span
                style="
                  font-family: 'IBM Plex Sans Thai';
                  font-style: normal;
                  font-weight: 600;
                  font-size: 24px;
                  line-height: 40px;
                "
              >
                โครงสร้างหลักสูตร
              </span>
            </v-col>
            <v-col cols="12" md="2" sm="2" class="mt-10 ml-n5 text-right">
              <v-btn disabled @click="addStructure()" color="#2AB3A3" dark
                >(+) เพิ่มโครงสร้างหลักสูตร</v-btn
              >
            </v-col>
          </v-row>
          <v-card class="pa-6" width="100%">
            <v-row v-if="!form4.courseStructure.length">
              <v-col cols="12" md="12" sm="12" class="text-center">
                <span
                  ><b><span>ไม่มีข้อมูลโครงสร้างหลักสูตร</span></b>
                </span>
              </v-col>
            </v-row>
            <v-row
              v-for="(itemStructure, index) in form4.courseStructure"
              :key="index"
            >
              <v-col cols="12" md="3" sm="3">
                <span class="pt-2 text-h6"
                  >ชื่อโครงสร้างหลักสูตร #{{ index + 1 }}</span
                >
              </v-col>
              <v-col cols="12" md="7" sm="7">
                <v-text-field
                  hide-details
                  v-model="itemStructure.name"
                  placeholder="ชื่อโครงสร้างหลักสูตร"
                  dense
                  outlined
                  disabled
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="2" sm="2">
                <v-btn disabled @click="deleteStructure(index)" color="red" dark
                  >(-) ลบโครงสร้างหลักสูตร #{{ index + 1 }}</v-btn
                >
              </v-col>
              <v-col cols="12" md="3" sm="3">
                <span class="pt-2 text-h6">หมายเหตุ</span>
              </v-col>
              <v-col cols="12" md="7" sm="7">
                <v-text-field
                  hide-details
                  v-model="itemStructure.note"
                  placeholder="หมายเหตุ"
                  dense
                  outlined
                  disabled
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3" sm="3">
                <span class="text-h6">หมวดวิชา</span>
              </v-col>
              <v-col cols="12" md="9" sm="9">
                <span class="text-h6">หน่วยกิต</span>
              </v-col>
              <v-col cols="12" md="3" sm="3">
                <span class="pt-2">วิชาเสริมพื้นฐาน</span>
              </v-col>
              <v-col cols="12" md="7" sm="7">
                <v-text-field
                  hide-details
                  v-model="itemStructure.sub1"
                  placeholder="วิชาเสริมพื้นฐาน (หน่วยกิต)"
                  dense
                  outlined
                  disabled
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3" sm="3">
                <span class="pt-2">วิชาหลัก</span>
              </v-col>
              <v-col cols="12" md="7" sm="7">
                <v-text-field
                  hide-details
                  v-model="itemStructure.sub2"
                  placeholder="วิชาหลัก (หน่วยกิต)"
                  dense
                  outlined
                  disabled
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3" sm="3">
                <span class="pt-2">วิชาเอก</span>
              </v-col>
              <v-col cols="12" md="7" sm="7">
                <v-text-field
                  hide-details
                  v-model="itemStructure.sub3"
                  placeholder="วิชาเอก (หน่วยกิต)"
                  dense
                  outlined
                  disabled
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3" sm="3">
                <span class="pt-2">วิชาเลือก</span>
              </v-col>
              <v-col cols="12" md="7" sm="7">
                <v-text-field
                  hide-details
                  v-model="itemStructure.sub4"
                  placeholder="วิชาเลือก (หน่วยกิต)"
                  dense
                  outlined
                  disabled
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3" sm="3">
                <span class="pt-2">วิชาการค้นคว้าอิสระ</span>
              </v-col>
              <v-col cols="12" md="7" sm="7">
                <v-text-field
                  hide-details
                  v-model="itemStructure.sub5"
                  placeholder="วิชาการค้นคว้าอิสระ (หน่วยกิต)"
                  dense
                  outlined
                  disabled
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3" sm="3">
                <span class="pt-2">วิทยานิพนธ์</span>
              </v-col>
              <v-col cols="12" md="7" sm="7">
                <v-text-field
                  hide-details
                  v-model="itemStructure.sub6"
                  placeholder="วิทยานิพนธ์ (หน่วยกิต)"
                  dense
                  outlined
                  disabled
                ></v-text-field>
              </v-col>

              <v-col cols="12">
                <v-divider></v-divider>
              </v-col>
            </v-row>
          </v-card>
          <!-- <v-col cols="12" md="12" sm="12" class="text-right">
            <v-btn @click="e1 = 2"> Back </v-btn>
            &nbsp;
            <v-btn color="#2AB3A3" dark @click="e1 = 4"> ถัดไป </v-btn>
          </v-col> -->
        </v-card>
      </v-tab-item>

      <v-tab-item>
        <v-card color="#f5f5f5" class="pa-6">
          <v-row>
            <v-col cols="12" md="10" sm="10" class="mt-10 ml-5">
              <span
                style="
                  font-family: 'IBM Plex Sans Thai';
                  font-style: normal;
                  font-weight: 600;
                  font-size: 24px;
                  line-height: 40px;
                "
              >
                ค่าเล่าเรียน
              </span>
            </v-col>
            <v-col cols="12" md="2" sm="2" class="mt-10 ml-n5 text-right">
              <v-btn disabled @click="addPriceCourse()" color="#2AB3A3" dark
                >(+) เพิ่มค่าเล่าเรียน</v-btn
              >
            </v-col>
          </v-row>
          <v-card class="pa-6" width="100%">
            <v-row v-if="!form5.priceCourse.length">
              <v-col cols="12" md="12" sm="12" class="text-center">
                <span
                  ><b><span>ไม่มีข้อมูลค่าเล่าเรียน</span></b>
                </span>
              </v-col>
            </v-row>
            <v-row v-for="(itemPrice, index) in form5.priceCourse" :key="index">
              <v-col cols="12" md="10" sm="10" class="text-h6">
                ค่าเล่าเรียน #{{ index + 1 }}
              </v-col>
              <v-col cols="12" md="2" sm="2" class="text-right">
                <v-btn
                  disabled
                  @click="deletePriceCourse(index)"
                  color="red"
                  dark
                  >(-) ลบค่าเล่าเรียนที่ #{{ index + 1 }}</v-btn
                >
              </v-col>
              <v-col cols="12" md="2" sm="2"> สำหรับการเรียนการสอน </v-col>
              <v-col cols="12" md="7" sm="7">
                <v-text-field
                  hide-details
                  v-model="itemPrice.forCourse"
                  placeholder="Ex. สำหรับการเรียนการสอนภาษาไทย"
                  auto-grow
                  dense
                  outlined
                  disabled
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3" sm="3"></v-col>

              <v-card class="pa-6" width="100%" flat>
                <v-row
                  v-for="(elementItems, index1) in itemPrice.priceItems"
                  :key="index1"
                >
                  <v-col cols="12" md="2" sm="2">
                    ราคาที่ #{{ index1 + 1 }}</v-col
                  >
                  <v-col cols="12" md="10" sm="10">
                    <v-btn
                      @click="deletePriceItems(index, index1)"
                      color="red"
                      dark
                      disabled
                      >(-) ลบราคา #{{ index1 + 1 }}</v-btn
                    >
                  </v-col>
                  <v-col cols="12" md="2" sm="2"> เลือกภาค </v-col>
                  <v-col cols="12" md="7" sm="7">
                    <v-select
                      v-model="elementItems.termType"
                      :items="['ภาคปกติ', 'ภาคพิเศษ']"
                      dense
                      outlined
                      disabled
                    ></v-select>
                  </v-col>
                  <v-col md="3" sm="3"></v-col>
                  <v-col cols="12" md="2" sm="2"
                    >เลือกรูปแบบหลักสูตร (ถ้ามี)</v-col
                  >
                  <v-col cols="12" md="7" sm="7">
                    <v-select
                      v-model="elementItems.typeCourseId"
                      :items="itemsTypeCourse"
                      item-value="id"
                      dense
                      outlined
                      disabled
                    >
                      <template v-slot:selection="{ item }">
                        {{ item.name_th }} - {{ item.name_en }}
                      </template>
                      <template v-slot:item="{ item }">
                        {{ item.name_th }} - {{ item.name_en }}
                      </template>
                    </v-select>
                  </v-col>
                  <v-col md="3" sm="3"></v-col>
                  <v-col cols="12" md="2" sm="2"> ราคาต่อหน่วยกิต </v-col>
                  <v-col cols="12" md="7" sm="7">
                    <v-text-field
                      hide-details
                      v-model="elementItems.pricePerUnit"
                      placeholder="ราคาต่อหน่วยกิต"
                      auto-grow
                      dense
                      outlined
                      disabled
                    ></v-text-field>
                  </v-col>
                  <v-col md="3" sm="3"></v-col>
                  <v-col cols="12" md="2" sm="2">
                    ค่าใช้จ่ายตลอดหลักสูตรโดยประมาณ
                  </v-col>
                  <v-col cols="12" md="7" sm="7">
                    <v-text-field
                      hide-details
                      v-model="elementItems.summaryPrice"
                      placeholder="ค่าใช้จ่ายตลอดหลักสูตรโดยประมาณ"
                      auto-grow
                      dense
                      outlined
                      disabled
                    ></v-text-field>
                  </v-col>
                  <v-col md="3" sm="3"></v-col>
                  <v-col cols="12" md="12" sm="12">
                    <v-divider></v-divider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="2" sm="2"> </v-col>
                  <v-col cols="12" md="9" sm="9">
                    <v-btn
                      disabled
                      @click="addPriceItems(index)"
                      color="#2AB3A3"
                      dark
                      >(+) เพิ่มราคา</v-btn
                    >
                  </v-col>
                </v-row>
              </v-card>
              <v-col cols="12" md="12" sm="12" class="">
                <v-divider class="mt-5"></v-divider>
              </v-col>
            </v-row>
          </v-card>
          <!-- <v-col cols="12" md="12" sm="12" class="text-right">
            <v-btn @click="e1 = 3"> Back </v-btn>
            &nbsp;
            <v-btn color="#2AB3A3" dark @click="e1 = 5"> ถัดไป </v-btn>
          </v-col> -->
        </v-card>
      </v-tab-item>

      <v-tab-item>
        <v-card color="#f5f5f5" class="pa-6">
          <v-col cols="12" md="12" sm="12" class="mt-n1 ml-n3">
            <span
              style="
                font-family: 'IBM Plex Sans Thai';
                font-style: normal;
                font-weight: 600;
                font-size: 24px;
                line-height: 40px;
              "
              >เวลาเรียน/ภาคเรียน</span
            >
          </v-col>

          <v-card class="pa-6 mt-1" width="100%">
            <v-row>
              <v-col
                cols="12"
                md="12"
                sm="12"
                style="
                  font-style: normal;
                  font-weight: 600;
                  font-size: 22px;
                  line-height: 28px;
                "
              >
                เวลาเรียน
              </v-col>
            </v-row>
            <v-row
              v-for="(itemStudyTime, index) in form6.study_day_times"
              :key="index"
              class="mt-n2 mb-n5"
            >
              <v-col cols="12" md="3" sm="3">
                <v-text-field
                  hide-details
                  v-model="itemStudyTime.day"
                  placeholder="วันที่"
                  auto-grow
                  dense
                  outlined
                  disabled
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3" sm="3">
                <v-text-field
                  hide-details
                  v-model="itemStudyTime.time"
                  placeholder="เวลา"
                  auto-grow
                  dense
                  outlined
                  disabled
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="5" sm="5">
                <v-btn disabled @click="deleteTimeStudy(index)">(-) ลบ</v-btn>
              </v-col>
            </v-row>
            <!-- <v-row v-if="!form6.study_day_times.length">
              <v-col cols="12" md="12" sm="12">
                <span
                  ><b><span>ไม่มีข้อมูลเวลาเรียน</span></b>
                </span>
              </v-col>
            </v-row> -->
            <v-row class="mt-n8">
              <v-col cols="12" md="1" sm="1"> </v-col>
              <v-col cols="12" md="11" sm="11">
                <v-btn disabled @click="addTimeStudy()"
                  >(+) เพิ่มเวลาเรียน</v-btn
                >
              </v-col>
            </v-row>

            <v-row class="mt-15"></v-row>

            <v-row>
              <v-col
                cols="12"
                md="12"
                sm="12"
                style="
                  font-style: normal;
                  font-weight: 600;
                  font-size: 22px;
                  line-height: 28px;
                "
              >
                ภาคเรียน
              </v-col>
            </v-row>
            <v-row
              v-for="(itemSemester, index) in form6.semesters"
              :key="index"
              class="mt-n2 mb-n5"
            >
              <v-col cols="12" md="3" sm="3">
                <v-text-field
                  hide-details
                  v-model="itemSemester.semester_name"
                  placeholder="ชื่อภาคเรียน"
                  auto-grow
                  dense
                  outlined
                  disabled
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3" sm="3">
                <v-text-field
                  hide-details
                  v-model="itemSemester.semester_month"
                  placeholder="ช่วงเดือน"
                  auto-grow
                  dense
                  outlined
                  disabled
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="5" sm="5">
                <v-btn disabled @click="deleteSemester(index)">(-) ลบ</v-btn>
              </v-col>
            </v-row>
            <!-- <v-row v-if="!form6.semesters.length">
              <v-col cols="12" md="12" sm="12">
                <span
                  ><b><span>ไม่มีข้อมูลภาคเรียน</span></b>
                </span>
              </v-col>
            </v-row> -->
            <v-row class="mt-n8">
              <v-col cols="12" md="1" sm="1"> </v-col>
              <v-col cols="12" md="11" sm="11">
                <v-btn disabled @click="addSemester()">(+) เพิ่มภาคเรียน</v-btn>
              </v-col>
            </v-row>
          </v-card>

          <v-col cols="12" class="mt-10">
            <span
              style="
                font-family: 'IBM Plex Sans Thai';
                font-style: normal;
                font-weight: 600;
                font-size: 24px;
                line-height: 40px;
              "
            >
              โอกาสในการประกอบอาชีพ
            </span>
          </v-col>
          <v-card class="pa-6" width="100%">
            <v-col cols="12">
              <v-checkbox
                v-model="checkBox3"
                label="โอกาสในการประกอบอาชีพ (ถ้ามี)"
                disabled
              ></v-checkbox>
            </v-col>
            <v-row v-if="checkBox3 == true">
              <v-col cols="12" md="3" sm="3"
                ><span class="pt-2">โอกาสในการประกอบอาชีพ (TH)</span>
              </v-col>
              <v-col cols="12" md="7" sm="7">
                <quill-editor
                  v-model="form6.job_opportunities_th"
                  :content="content3"
                  :options="editorOption"
                  @change="onEditorChange3($event)"
                  disabled
                />
              </v-col>
              <v-col cols="12" md="3" sm="3"
                ><span class="pt-2">โอกาสในการประกอบอาชีพ (EN)</span>
              </v-col>
              <v-col cols="12" md="7" sm="7">
                <quill-editor
                  v-model="form6.job_opportunities_en"
                  :content="content4"
                  :options="editorOption"
                  @change="onEditorChange4($event)"
                  disabled
                />
              </v-col>
            </v-row>
          </v-card>

          <v-row class="pa-3 mt-7">
            <v-col cols="12" md="10" sm="10">
              <span
                style="
                  font-family: 'IBM Plex Sans Thai';
                  font-style: normal;
                  font-weight: 600;
                  font-size: 24px;
                  line-height: 40px;
                "
              >
                ผลการเรียนรู้ที่คาดหวัง
              </span>
            </v-col>
            <v-col cols="12" md="2" sm="2">
              <!-- <DialogHopefuls
                :courseId="courseId"
                @getAllHopeful="getAllHopeful"
              /> -->
            </v-col>
          </v-row>
          <v-card class="pa-6" width="100%">
            <v-row>
              <!-- <v-col cols="12" md="2" sm="2" class="text-h6">
                <span class="pt-2">ผลการเรียนรู้ที่คาดหวัง</span>
              </v-col>
              <v-col cols="12" md="10" sm="10">
                <DialogHopefuls
                  :courseId="courseId"
                  @getAllHopeful="getAllHopeful"
                />
              </v-col> -->
              <v-col cols="12" md="12" sm="12">
                <v-card
                  ><v-data-table
                    :headers="headersHopeful"
                    :items="itemsHopeful"
                    :search="searchHopeful"
                    :items-per-page="5"
                    class="elevation-1"
                  >
                    <template v-slot:[`item.img_url`]="{ item }">
                      <v-img
                        :src="item.img_url"
                        width="120px"
                        class="ma-1"
                      ></v-img>
                    </template>
                    <!-- <template v-slot:[`item.action`]="{ item }">
                      <v-icon @click="DeleteItemHopeful(item)"
                        >mdi-delete</v-icon
                      >
                    </template> -->
                  </v-data-table>
                </v-card>
              </v-col>
            </v-row>
          </v-card>

          <v-row class="pa-3 mt-7">
            <v-col cols="12" md="10" sm="10">
              <span
                style="
                  font-family: 'IBM Plex Sans Thai';
                  font-style: normal;
                  font-weight: 600;
                  font-size: 24px;
                  line-height: 40px;
                "
              >
                FAQ
              </span>
            </v-col>
            <v-col cols="12" md="2" sm="2">
              <!-- <DialogFaqs :courseId="courseId" @getAllFaqs="getAllFaqs" /> -->
            </v-col>
          </v-row>
          <v-card class="pa-6" width="100%">
            <v-row>
              <!-- <v-col cols="12" md="1" sm="1" class="text-h6">
                <span class="pt-2">FAQ</span>
              </v-col>
              <v-col cols="12" md="11" sm="11">
                <DialogFaqs :courseId="courseId" @getAllFaqs="getAllFaqs" />
              </v-col> -->
              <v-col cols="12" md="12" sm="12">
                <v-card
                  ><v-data-table
                    :headers="headersFaqs"
                    :items="itemsFaqs"
                    :items-per-page="5"
                    class="elevation-1"
                  >
                    <!-- <template v-slot:[`item.action`]="{ item }">
                      <v-icon @click="DeleteItemFaqs(item)">mdi-delete</v-icon>
                    </template> -->
                  </v-data-table>
                </v-card>
              </v-col>
            </v-row>
          </v-card>

          <v-row class="pa-3 mt-7">
            <v-col cols="12" md="10" sm="10">
              <span
                style="
                  font-family: 'IBM Plex Sans Thai';
                  font-style: normal;
                  font-weight: 600;
                  font-size: 24px;
                  line-height: 40px;
                "
              >
                รีวิว
              </span>
            </v-col>
            <v-col cols="12" md="2" sm="2">
              <!-- <DialogReview :courseId="courseId" @getAllReview="getAllReview" /> -->
              <DialogReviewEdit
                :openDialogReviewEdit="openDialogReviewEdit"
                :courseId="courseId"
                :itemDialogReviewEdit="itemDialogReviewEdit"
                @getAllReview="getAllReview"
                @closeDialogReviewEdit="closeDialogReviewEdit"
              />
            </v-col>
          </v-row>
          <v-card class="pa-6" width="100%">
            <v-row>
              <!-- <v-col cols="12" class="text-h6">
                <span class="pt-2">รีวิว</span>
              </v-col> -->
              <!-- <v-col cols="12" md="2" sm="2">
              <v-btn @click="goToCreateReview()" color="#2AB3A3"
                >เพิ่มรีวิว</v-btn
              >
              <v-col cols="12" md="2" sm="2">
              <v-btn @click="goToCreateReview()" color="#2AB3A3"
                >เพิ่มรีวิว</v-btn
              >
            </v-col> -->
              <!-- <v-col cols="12" md="11" sm="11">
                <DialogReview
                  :courseId="courseId"
                  @getAllReview="getAllReview"
                />
                <DialogReviewEdit
                  :openDialogReviewEdit="openDialogReviewEdit"
                  :courseId="courseId"
                  :itemDialogReviewEdit="itemDialogReviewEdit"
                  @getAllReview="getAllReview"
                  @closeDialogReviewEdit="closeDialogReviewEdit"
                />
              </v-col> -->
              <v-col cols="12">
                <v-card
                  ><v-data-table
                    :headers="headersReview"
                    :items="itemsReviews"
                    :items-per-page="5"
                    class="elevation-1"
                  >
                    <template v-slot:[`item.img_url`]="{ item }">
                      <v-img
                        :src="item.img_url"
                        width="120px"
                        class="ma-1"
                      ></v-img>
                    </template>
                    <!-- <template v-slot:[`item.action`]="{ item }">
                      <v-icon @click="UpdateReview(item)">mdi-pencil</v-icon>
                      <v-icon @click="DeleteItemReview(item)"
                        >mdi-delete</v-icon
                      >
                    </template> -->
                  </v-data-table>
                </v-card>
              </v-col>
            </v-row>
          </v-card>

          <v-row>
            <v-col cols="12" md="12" sm="12" class="mt-10">
              <span
                style="
                  font-family: 'IBM Plex Sans Thai';
                  font-style: normal;
                  font-weight: 600;
                  font-size: 24px;
                  line-height: 40px;
                "
                >รูปบรรยากาศ</span
              >
            </v-col>
          </v-row>
          <v-card class="pa-6 mt-4" width="100%">
            <v-row>
              <v-col
                v-for="(item, indexItemPic) in this.showImageUpload"
                :key="indexItemPic"
                class="d-flex child-flex"
                cols="12"
              >
                <v-img
                  :src="item.path"
                  contain
                  max-height="400px"
                  min-height="400px"
                >
                  <v-row>
                    <v-col>
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height ma-0"
                          align="center"
                          justify="center"
                        >
                          <v-progress-circular
                            indeterminate
                            color="grey lighten-5"
                          ></v-progress-circular>
                        </v-row>
                      </template>
                    </v-col>
                    <v-col class="text-right">
                      <v-btn
                        disabled
                        x-small
                        style="
                          border: solid 1px;
                          border-radius: 0px;
                          opacity: 0.4;
                        "
                        @click="pictureDelete(indexItemPic)"
                        >X</v-btn
                      >
                    </v-col>
                  </v-row>
                </v-img>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="12" sm="12">
                <VueFileAgent
                  ref="vueFileAgent"
                  :theme="'grid'"
                  :multiple="true"
                  :deletable="true"
                  :meta="true"
                  :disabled="true"
                  :accept="'image/*'"
                  :helpText="'Choose images files or drag and drop here'"
                  :errorText="{
                    type: 'Invalid file type. Only images Allowed',
                  }"
                  @select="filesSelected($event)"
                  @beforedelete="onBeforeDelete($event)"
                  @delete="fileDeleted($event)"
                  v-model="fileRecords"
                ></VueFileAgent>
              </v-col>
              <v-col cols="12" md="12" sm="12" class="text-center">
                <v-btn
                  disabled
                  @click="uploadFiles()"
                  color="#2AB3A3"
                  dark
                  x-large
                >
                  Upload {{ fileRecordsForUpload.length }} files
                </v-btn>
              </v-col>
            </v-row>
          </v-card>

          <!-- <v-col cols="12" md="12" sm="12" class="text-right">
            <v-btn @click="e1 = 4"> Back </v-btn>
            &nbsp;
            <v-btn color="#2AB3A3" dark @click="cancel()"> กลับหน้าหลัก </v-btn>
          </v-col> -->
        </v-card>
      </v-tab-item>
    </v-tabs>
  </v-row>
</template>
<script>
import moment from "moment";
import { Decode, Encode } from "@/services";
import { VueEditor } from "vue2-editor";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import DialogMajor from "../Dialogs/DialogMajor.vue";
import DialogMajorEdit from "../Dialogs/DialogMajorEdit.vue";
import DialogHopefuls from "../Dialogs/DialogHopefuls.vue";
import DialogFaqs from "../Dialogs/DialogFaqs.vue";
import DialogReview from "../Dialogs/DialogReview.vue";
import DialogReviewEdit from "../Dialogs/DialogReviewEdit.vue";
export default {
  components: {
    Loading,
    VueEditor,
    DialogMajor,
    DialogMajorEdit,
    DialogHopefuls,
    DialogFaqs,
    DialogReview,
    DialogReviewEdit,
  },
  data() {
    return {
      fileRecords: [],
      uploadUrl: "",
      uploadHeaders: { "X-Test-Header": "vue-file-agent" },
      fileRecordsForUpload: [],
      itemsUploadFile: [],
      showImageUpload: [],

      status: "เปิดใช้งาน",
      statusItems: [
        { id: 1, name: "เปิดใช้งาน" },
        {
          id: 2,
          name: "ปิดใช้งาน",
        },
        {
          id: 3,
          name: "ฉบับร่าง",
        },
      ],
      openDialogMajorEdit: false,
      openDialogReviewEdit: false,
      isSubmit3: false,
      isSubmit4: false,
      isSubmit5: false,
      getToday: new Date().toLocaleString(),
      today: "",

      e1: 0,
      form1: {
        title_th: null,
        title_en: null,
        short_desc_th: null,
        short_desc_en: null,
        course_highlights_th: null,
        course_highlights_en: null,
        img_url: "",
        img_url1: "",
        showImage: "",
        showImage1: "",
      },
      form2: {},
      form3: {
        majors: [
          {
            name_th: null,
            name_en: null,
            desc_th: null,
            desc_en: null,
            subject: [{ id: null }],
            job_opportunities: null,
            job_opportunities_en: null,
          },
        ],

        typeCourse: [
          {
            name_th: null,
            name_en: null,
            desc_th: null,
            desc_en: null,
            subject: [{ id: null }],
          },
        ],
      },
      form4: {
        courseStructure: [
          {
            name: "",
            sub1: null,
            sub2: null,
            sub3: null,
            sub4: null,
            sub5: null,
            sub6: null,
            sub7: null,
            note: "",
          },
        ],
      },
      form5: {
        priceCourse: [
          {
            forCourse: null,
            priceItems: [
              {
                termType: "",
                typeCourseId: null,
                pricePerUnit: null,
                summaryPrice: null,
              },
            ],
          },
        ],
      },
      form6: {
        job_opportunities_th: null,
        job_opportunities_en: null,
        study_day_times: [
          {
            day: null,
            time: null,
          },
        ],
        semesters: [
          {
            semester_name: null,
            semester_month: null,
          },
        ],
        img_url: "",
        showImage: "",
      },
      checkBox1: false,
      checkBox2: false,
      checkBox3: false,
      majors: [],
      course_level: [],
      facualtyId: [],
      majorId: [],
      title_th: "",
      title_en: "",
      short_desc_th: "",
      short_desc_en: "",
      full_desc_th: "",
      full_desc_en: "",
      description_th: "",
      description_en: "",
      course_highlights: null,
      tuition_per_credit: null,
      estimated_tuition_fees: null,
      tuition_per_credit_special_part: null,
      estimated_tuition_fees_special_part: null,
      study_time_special_part: null,
      study_time: null,
      semester1: null,
      semester2: null,
      semester3: null,
      semester1_special_part: null,
      semester2_special_part: null,
      semester3_special_part: null,
      img_url: "",
      img_url1: "",
      content: "",
      content1: "",
      content2: "",
      content3: "",
      content4: "",
      content5: "",
      keyImg: "",
      activeFlag: true,

      editorOption: {
        placeholder: "ระบุข้อมูล",
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"],
            ["blockquote", "code-block"],
            [{ header: 1 }, { header: 2 }],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ script: "sub" }, { script: "super" }],
            [{ indent: "-1" }, { indent: "+1" }],
            [{ direction: "rtl" }],
            [{ size: ["small", false, "large", "huge"] }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ font: [] }],
            [{ color: [] }, { background: [] }],
            [{ align: [] }],
            ["clean"],
            [
              "link",
              "image",
              // "video"
            ],
          ],
          syntax: {
            highlight: (text) => hljs.highlightAuto(text).value,
          },
        },
      },

      pic1: "",
      picture_1: "",
      pic1URL: "",
      showImage: "",
      showImage1: "",
      imageName1: "",
      rules: {
        required: [(v) => !!v || "กรุณากรอกข้อมูล"],
        pic: [(v) => !!v || "กรุณาอัพโหลดรูป"],
      },
      checkValidate: true,
      lazy: false,
      user: "",
      linkUrl: "",
      productId: 0,
      items: [],
      itemsMajorSub: [],
      itemsMajorSubStable: [],
      category: "",
      itemsCategory: [],
      type: "CONTENT",
      category: "",
      desc: "",
      itemsType: [],
      brand: "",
      itemsBrand: [],
      img: "",
      content_url: "",
      loading: false,
      file: [],
      video_url: "",
      itemTypes: [
        {
          name: "CONTENT",
          val: "CONTENT",
        },
        {
          name: "VIDEO",
          val: "VIDEO",
        },
      ],
      itemCategory: [
        {
          name: "แนะนำ",
          val: "SUGGEST",
        },
        {
          name: "ครอบครัว",
          val: "HOME2",
        },
        {
          name: "งานวิจัย",
          val: "RESEARCH",
        },
      ],
      levelItems: [],
      FacultyItems: [],
      majorItems: [],

      headersMajorSub: [
        { text: "ลำดับ", value: "sortindex", align: "start" },
        { text: "หมวดวิชา", value: "subjectGroup", align: "start" },
        { text: "รหัสรายวิชา", value: "subjectCode", align: "start" },
        { text: "ชื่อรายวิชา (TH)", value: "name_th", align: "start" },
        { text: "รายละเอียด (TH)", value: "description_th", align: "start" },
        { text: "ปุ่มจัดการ", value: "action", align: "center" },
      ],
      subItems: [
        { id: 0, text: "ทั้งหมด" },
        { id: 1, text: "หมวดวิชาเสริมพื้นฐาน (เฉพาะภาคปกติ)" },
        { id: 2, text: "หมวดวิชาหลัก" },
        { id: 3, text: "หมวดวิชาเอก" },
        { id: 4, text: "หมวดวิชาเลือก" },
        { id: 5, text: "วิทยานิพนธ์/วิชาการค้นคว้าอิสระ" },
      ],
      searchMajorSub: "",
      filterMajor: "ทั้งหมด",

      headersHopeful: [
        { text: "ลำดับ", value: "sortindex", align: "start" },
        {
          text: "ผลการเรียนรู้ที่คาดหวัง (TH)",
          value: "description_th",
          align: "start",
        },
        {
          text: "ผลการเรียนรู้ที่คาดหวัง (EN)",
          value: "description_en",
          align: "start",
        },
        { text: "ปุ่มจัดการ", value: "action", align: "center" },
      ],
      headersFaqs: [
        { text: "ลำดับ", value: "sortindex", align: "start" },
        {
          text: "คำถาม (TH)",
          value: "question_th",
          align: "start",
        },
        {
          text: "คำตอบ (TH)",
          value: "answer_th",
          align: "start",
        },
        {
          text: "คำถาม (EN)",
          value: "question_en",
          align: "start",
        },
        {
          text: "คำตอบ (EN)",
          value: "answer_en",
          align: "start",
        },
        { text: "ปุ่มจัดการ", value: "action", align: "center" },
      ],
      headersReview: [
        { text: "ลำดับ", value: "sortindex", align: "start" },
        {
          text: "รูปภาพ",
          value: "img_url",
          align: "start",
        },
        {
          text: "ชื่อ (TH)",
          value: "name_th",
          align: "start",
        },
        {
          text: "ตำแหน่งและที่ทำงานปัจจุบัน (TH)",
          value: "current_job_th",
          align: "start",
        },
        {
          text: "วันที่",
          value: "date",
          align: "start",
        },
        { text: "ปุ่มจัดการ", value: "action", align: "center" },
      ],
      itemsHopeful: [],
      itemsFaqs: [],
      itemsReviews: [],
      CourseData: [],
      searchHopeful: "",
      itemsCourse: [],
      courseId: null,
      itemDialogMajorEdit: {},
      itemDialogReviewEdit: {},
      itemsTypeCourse: [],
      itemForm3: [],
      itemForm4: [],
      itemForm5: [],
    };
  },
  watch: {
    productId(val) {
      if (val) {
        console.log("productId", val);
      }
    },
    itemForm3(val) {
      if (val.majors) {
        this.checkBox1 = true;
      } else {
        this.checkBox1 = false;
      }

      if (val.typeCourse) {
        this.checkBox2 = true;
      } else {
        this.checkBox2 = false;
      }
    },
    "form6.job_opportunities_th"(val) {
      if (val) {
        this.checkBox3 = true;
      } else {
        this.checkBox3 = false;
      }
    },
  },
  computed: {
    isWindowWidthGreaterThan768() {
      return window.innerWidth > 768;
    },
  },
  created() {
    this.CourseData = JSON.parse(
      Decode.decode(localStorage.getItem("CourseData"))
    );
    this.courseId = this.CourseData.id;
    console.log(this.courseId, "this.courseId");
    this.getOneForm1();
    this.getMajorSub();
    this.getForm3();
    this.getForm4();
    this.getForm5();
    this.getForm6();
    this.getAllHopeful();
    this.getAllFaqs();
    this.getAllReview();
    this.getUploadFile();
  },
  methods: {
    async pictureDelete(val) {
      this.showImageUpload.splice(val, 1);
    },
    async getUploadFile() {
      this.loading = true;
      const auth = {
        headers: { Authorization: `Bearer ${this.user.token}` },
      };
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/atmospheres?courseId=${this.courseId}`,
        auth
      );
      console.log("getUploadFile", response.data.data);
      this.itemsUploadFile = response.data.data;
      this.showImageUpload = response.data.data[0].pathPic;
      console.log("showImageUpload", this.showImageUpload);
      this.loading = false;
    },
    async uploadFiles() {
      const auth = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      let pathPic = [];
      pathPic = this.showImageUpload;

      for (let i = 0; i < this.fileRecords.length; i++) {
        let formData = new FormData();
        formData.append("files", this.fileRecords[i].file);
        formData.append("filename", this.fileRecords[i].file.name);
        formData.append("type", "uploadfile");

        const response = await this.axios.post(
          `https://gscm-service.yuzudigital.com/files/formData`,
          formData,
          auth
        );
        pathPic.push({ path: response.data.data.path });
        console.log("pathPic", pathPic);
        console.log("fileRecords", this.fileRecords);
      }
      this.uploadFiles1(pathPic);
      this.fileRecords = [];
      this.fileRecordsForUpload = [];
    },
    async uploadFiles1(pathPic) {
      // console.log("itemsUploadFile", this.itemsUploadFile[0].id);
      const auth = {
        headers: { Authorization: `Bearer ${this.user.token}` },
      };
      const data = {
        courseId: this.courseId,
        pathPic: pathPic,
        fileRecords: this.fileRecords,
      };
      const response = await this.axios.put(
        `${process.env.VUE_APP_API}/atmospheres/${this.itemsUploadFile[0].id}`,
        data,
        auth
      );
      console.log("uploadPathPic", response);
      console.log(response.data.response_status);
    },
    deleteUploadedFile: function (fileRecord) {
      // Using the default uploader. You may use another uploader instead.
      this.$refs.vueFileAgent.deleteUpload(
        this.uploadUrl,
        this.uploadHeaders,
        fileRecord
      );
    },
    filesSelected: function (fileRecordsNewlySelected) {
      var validFileRecords = fileRecordsNewlySelected.filter(
        (fileRecord) => !fileRecord.error
      );
      this.fileRecordsForUpload =
        this.fileRecordsForUpload.concat(validFileRecords);
    },
    onBeforeDelete: function (fileRecord) {
      var i = this.fileRecordsForUpload.indexOf(fileRecord);
      if (i !== -1) {
        // queued file, not yet uploaded. Just remove from the arrays
        this.fileRecordsForUpload.splice(i, 1);
        var k = this.fileRecords.indexOf(fileRecord);
        if (k !== -1) this.fileRecords.splice(k, 1);
      } else {
        if (confirm("Are you sure you want to delete?")) {
          this.$refs.vueFileAgent.deleteFileRecord(fileRecord); // will trigger 'delete' event
        }
      }
    },
    fileDeleted: function (fileRecord) {
      var i = this.fileRecordsForUpload.indexOf(fileRecord);
      if (i !== -1) {
        this.fileRecordsForUpload.splice(i, 1);
      } else {
        this.deleteUploadedFile(fileRecord);
      }
    },

    convertDate(val) {
      return moment(val).format("DD/MM/YYYY");
    },
    getOneForm1() {
      console.log(this.CourseData, "this.CourseData");
      this.form1 = this.CourseData;
      this.status = this.CourseData.status;
      this.form1.showImage = this.form1.img_url;
      this.form1.showImage1 = this.form1.img_url1;
    },
    async getForm3() {
      this.loading = true;
      const auth = {
        headers: { Authorization: `Bearer ${this.user.token}` },
      };
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/majors/form3/${this.courseId}`,
        auth
      );
      console.log("getForm3", response.data.data);
      this.itemForm3 = response.data.data;
      this.form3 = this.itemForm3;
      this.getTypeCourse();
      this.loading = false;
    },

    async getForm4() {
      this.loading = true;
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/courseStructures/form4/${this.courseId}`
      );
      console.log("getForm4", response.data.data);
      this.form4 = response.data.data;
      this.loading = false;
    },

    async getForm5() {
      this.loading = true;
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/priceCourses/form5/${this.courseId}`
      );
      console.log("getForm5", response.data.data);
      this.form5.priceCourse = response.data.data;
      this.loading = false;
    },
    async getForm6() {
      this.loading = true;
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/courses/form6/${this.courseId}`
      );
      console.log("getForm6", response.data.form6);
      this.form6 = response.data.form6;
      this.loading = false;
    },

    addSemester() {
      this.form6.semesters.push({
        semester_name: null,
        semester_month: null,
      });
    },
    addTimeStudy() {
      this.form6.study_day_times.push({
        day: null,
        time: null,
      });
    },
    addPriceItems(index) {
      this.form5.priceCourse[index].priceItems.push({
        termType: "",
        typeCourseId: null,
        pricePerUnit: null,
        summaryPrice: null,
      });
    },
    addPriceCourse() {
      this.form5.priceCourse.push({
        forCourse: null,
        priceItems: [
          {
            termType: "",
            typeCourseId: null,
            pricePerUnit: null,
            summaryPrice: null,
          },
        ],
      });
    },
    addStructure() {
      this.form4.courseStructure.push({
        name: "",
        sub1: null,
        sub2: null,
        sub3: null,
        sub4: null,
        sub5: null,
        sub6: null,
        sub7: null,
        note: "",
      });
    },
    addMajor() {
      this.form3.majors.push({
        name_th: null,
        name_en: null,
        desc_th: null,
        desc_en: null,
        subject: [{ id: null }],
        job_opportunities: null,
        job_opportunities_en: null,
      });
    },
    addTypeCourse() {
      this.form3.typeCourse.push({
        name_th: null,
        name_en: null,
        desc_th: null,
        desc_en: null,
        subject: [{ id: null }],
      });
    },
    addSubject(index) {
      this.form3.majors[index].subject.push({
        id: null,
      });
    },
    addSubject1(index) {
      this.form3.typeCourse[index].subject.push({
        id: null,
      });
    },
    deleteTimeStudy(index) {
      this.form6.study_day_times.splice(index, 1);
    },
    deleteSemester(index) {
      this.form6.semesters.splice(index, 1);
    },
    deletePriceItems(index, index1) {
      this.form5.priceCourse[index].priceItems.splice(index1, 1);
    },
    deletePriceCourse(index) {
      this.form5.priceCourse.splice(index, 1);
    },
    deleteStructure(index) {
      this.form4.courseStructure.splice(index, 1);
    },
    deleteMajor(index) {
      this.form3.majors.splice(index, 1);
    },
    deleteTypeCourse(index) {
      this.form3.typeCourse.splice(index, 1);
    },
    deleteSubject(index, index1) {
      this.form3.majors[index].subject.splice(index1, 1);
    },
    deleteSubject1(index, index1) {
      this.form3.typeCourse[index].subject.splice(index1, 1);
    },
    async getAllFaqs() {
      this.loading = true;
      const auth = {
        headers: { Authorization: `Bearer ${this.user.token}` },
      };
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/faqs?courseId=${this.courseId}`,
        auth
        // 'http://localhost:8081/types/'
      );
      console.log("getAllFaqs", response.data.data);
      this.itemsFaqs = response.data.data;
      // this.imgUrl = response.data.data.imgUrl;
      for (let i in this.itemsFaqs) {
        this.itemsFaqs[i].sortindex = parseInt(i) + 1;
      }
      this.loading = false;
    },

    async getAllHopeful() {
      this.loading = true;
      const auth = {
        headers: { Authorization: `Bearer ${this.user.token}` },
      };
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/expectedLearningOutcomes?courseId=${this.courseId}`,
        auth
        // 'http://localhost:8081/types/'
      );
      console.log("getAllHopeful", response.data.data);
      this.itemsHopeful = response.data.data;
      // this.imgUrl = response.data.data.imgUrl;
      for (let i in this.itemsHopeful) {
        this.itemsHopeful[i].sortindex = parseInt(i) + 1;
      }
      this.loading = false;
    },

    async getAllReview() {
      this.loading = true;
      const auth = {
        headers: { Authorization: `Bearer ${this.user.token}` },
      };
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/reviews?courseId=${this.courseId}`,
        auth
      );
      console.log("getAllReviews", response.data.data);
      this.itemsReviews = response.data.data;
      // this.imgUrl = response.data.data.imgUrl;
      for (let i in this.itemsReviews) {
        this.itemsReviews[i].sortindex = parseInt(i) + 1;
      }
      this.loading = false;
    },
    // async submitHopeful() {
    //   this.user = JSON.parse(Decode.decode(localStorage.getItem("gscmAdmin")));
    //   const auth = {
    //     headers: { Authorization: `Bearer ${this.user.token}` },
    //   };
    //   const data = {
    //     description_th: this.form6.description_th,
    //     description_en: this.form6.description_en,
    //   };
    //   const response = await this.axios.post(
    //     `${process.env.VUE_APP_API}/expectedLearningOutcomes`,
    //     data,
    //     auth
    //   );
    //   console.log(response.data.response_status);
    //   if (response.data.response_status == "SUCCESS") {
    //     this.$swal.fire({
    //       icon: "success",
    //       text: `เพิ่มผลการเรียนที่รู้คาดหวังสำเร็จ`,
    //       showConfirmButton: false,
    //       timer: 1500,
    //     });
    //     this.description = null;
    //     this.getAllHopeful();
    //   } else {
    //     this.$swal.fire({
    //       icon: "error",
    //       text: response.data.response_description,
    //       showConfirmButton: false,
    //       timer: 1500,
    //     });
    //   }
    // },
    async DeleteItemHopeful(val) {
      this.$swal({
        text: "คุณต้องการลบข้อมูลใช่ หรือไม่ ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "ยกเลิก",
        confirmButtonText: "ต้องการลบข้อมูล",
      }).then(async (result) => {
        const auth = {
          headers: { Authorization: `Bearer ${this.user.token}` },
        };
        if (result.isConfirmed) {
          const response = await this.axios.delete(
            `${process.env.VUE_APP_API}/expectedLearningOutcomes/` + val.id,
            auth
          );
          console.log(response);

          this.$swal.fire({
            icon: "success",
            text: "ลบข้อมูลสำเร็จ",
            showConfirmButton: false,
            timer: 1000,
          });
          // await location.reload();
          this.getAllHopeful();
        }
      });
    },
    async DeleteItemFaqs(val) {
      this.$swal({
        text: "คุณต้องการลบข้อมูลใช่ หรือไม่ ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "ยกเลิก",
        confirmButtonText: "ต้องการลบข้อมูล",
      }).then(async (result) => {
        const auth = {
          headers: { Authorization: `Bearer ${this.user.token}` },
        };
        if (result.isConfirmed) {
          const response = await this.axios.delete(
            `${process.env.VUE_APP_API}/faqs/` + val.id,
            auth
          );
          console.log(response);

          this.$swal.fire({
            icon: "success",
            text: "ลบข้อมูลสำเร็จ",
            showConfirmButton: false,
            timer: 1000,
          });
          // await location.reload();
          this.getAllFaqs();
        }
      });
    },
    async DeleteItemReview(val) {
      this.$swal({
        text: "คุณต้องการลบข้อมูลใช่ หรือไม่ ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "ยกเลิก",
        confirmButtonText: "ต้องการลบข้อมูล",
      }).then(async (result) => {
        const auth = {
          headers: { Authorization: `Bearer ${this.user.token}` },
        };
        if (result.isConfirmed) {
          const response = await this.axios.delete(
            `${process.env.VUE_APP_API}/reviews/` + val.id,
            auth
          );
          console.log(response);

          this.$swal.fire({
            icon: "success",
            text: "ลบข้อมูลสำเร็จ",
            showConfirmButton: false,
            timer: 1000,
          });
          // await location.reload();
          this.getAllReview();
        }
      });
    },

    // async getAllMajorSub() {
    //   this.loading = true;
    //   const auth = {
    //     headers: { Authorization: `Bearer ${this.user.token}` },
    //   };
    //   const response = await this.axios.get(
    //     `${process.env.VUE_APP_API}/coursesOffereds`,
    //     auth
    //     // 'http://localhost:8081/types/'
    //   );
    //   console.log("getAllMajorSub", response.data.data);
    //   this.itemsMajorSub = response.data.data;
    //   // this.imgUrl = response.data.data.imgUrl;
    //   for (let i in this.itemsMajorSub) {
    //     this.itemsMajorSub[i].sortindex = parseInt(i) + 1;
    //   }
    //   this.loading = false;
    // },
    filterSubjectGroup(filterMajor) {
      let allItems = this.itemsMajorSubStable;
      if (filterMajor == "ทั้งหมด") {
        this.itemsMajorSub = this.itemsMajorSubStable;
      } else {
        let filterItems = allItems.filter(
          (item) => item.subjectGroup == filterMajor
        );
        this.itemsMajorSub = filterItems;
      }
    },
    async getMajorSub() {
      this.loading = true;
      const auth = {
        headers: { Authorization: `Bearer ${this.user.token}` },
      };
      const response = await this.axios.get(
        // `${process.env.VUE_APP_API}/coursesOffereds`,
        `${process.env.VUE_APP_API}/coursesOffereds?courseId=${this.courseId}`,
        auth
        // 'http://localhost:8081/types/'
      );
      console.log("getMajorSub", response.data.data);
      this.itemsMajorSub = response.data.data;
      this.itemsMajorSubStable = response.data.data;
      // this.imgUrl = response.data.data.imgUrl;
      for (let i in this.itemsMajorSub) {
        this.itemsMajorSub[i].sortindex = parseInt(i) + 1;
      }
      this.loading = false;
    },
    async getLevel() {
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/levels`
      );
      this.levelItems = response.data.data;
      console.log("levelItems", this.levelItems);
    },
    async getFaculty(level) {
      this.FacultyItems = [];
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/facualties?levelId=${level}`
      );
      this.FacultyItems = response.data.data;
      console.log("FacultyItems", this.FacultyItems);
    },
    // async getMajor(facualtyId) {
    //   this.majorItems = [];
    //   const response = await this.axios.get(
    //     `${process.env.VUE_APP_API}/majors?facualtyId=${facualtyId}`
    //   );
    //   this.majorItems = response.data.data;
    //   console.log("majorItems", this.majorItems);
    // },
    async handleFileUpload(questionNo) {
      // this.data[questionNo] = this.$refs[questionNo].files[0];
      // console.log("file", this.data[questionNo]);
    },
    onEditorChange({ quill, html, text }) {
      console.log("editor change!", quill, html, text);
      this.content = html;
    },
    onEditorChange1({ quill, html, text }) {
      console.log("editor change!", quill, html, text);
      this.content1 = html;
    },
    onEditorChange2({ quill, html, text }) {
      console.log("editor change!", quill, html, text);
      this.content2 = html;
    },
    onEditorChange3({ quill, html, text }) {
      console.log("editor change!", quill, html, text);
      this.content3 = html;
    },
    onEditorChange4({ quill, html, text }) {
      console.log("editor change!", quill, html, text);
      this.content4 = html;
    },
    onEditorChange5({ quill, html, text }) {
      console.log("editor change!", quill, html, text);
      this.content5 = html;
    },
    showPicture1(e) {
      // console.log('index',index);
      console.log("55");
      const files = e.target.files;
      if (files[0] !== undefined) {
        this.picture_1 = files[0].name;
        const element = files[0];
        const reader = new FileReader();
        reader.readAsDataURL(element);
        reader.onload = () => {
          this.pic1 = reader.result;
          this.showImage = URL.createObjectURL(element);
          // console.log(this.pic1);
        };
      }
    },
    changePic1() {
      document.getElementById("picTure1").click();
    },
    onPickFile() {
      document.getElementById("file_input").click();
    },
    cancel() {
      this.$router.push("ManageCourse");
    },

    async DeleteItemMajorSub(val) {
      this.$swal({
        text: "คุณต้องการลบข้อมูลใช่ หรือไม่ ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "ยกเลิก",
        confirmButtonText: "ต้องการลบข้อมูล",
      }).then(async (result) => {
        const auth = {
          headers: { Authorization: `Bearer ${this.user.token}` },
        };
        if (result.isConfirmed) {
          const response = await this.axios.delete(
            `${process.env.VUE_APP_API}/coursesOffereds/` + val.id,
            auth
          );
          console.log(response);

          this.$swal.fire({
            icon: "success",
            text: "ลบข้อมูลสำเร็จ",
            showConfirmButton: false,
            timer: 1000,
          });
          // await location.reload();
          // await this.getAllMajorSub();
          await this.getMajorSub();
        }
      });
    },
    goToCreateMajorSub() {
      this.$router.push("CreateMajorSub");
    },
    async UpdateMajorSub(val) {
      const auth = {
        headers: { Authorization: `Bearer ${this.user.token}` },
      };
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/coursesOffereds/${val.id}`,
        auth
      );
      this.itemDialogMajorEdit = response.data.data;

      this.openDialogMajorEdit = true;
    },

    closeDialogMajorEdit() {
      this.openDialogMajorEdit = false;
    },
    closeDialogReviewEdit() {
      this.openDialogReviewEdit = false;
    },

    goToCreateReview() {
      this.$router.push({ path: "/CreateReview", query: {} });
    },
    async UpdateReview(val) {
      const auth = {
        headers: { Authorization: `Bearer ${this.user.token}` },
      };
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/reviews/${val.id}`,
        auth
      );
      this.itemDialogReviewEdit = response.data.data;

      this.openDialogReviewEdit = true;
    },

    async submitfileMain(questionNo) {
      if (this.$refs[questionNo].files[0]) {
        let formData = new FormData();
        formData.append("files", this.$refs[questionNo].files[0]);
        formData.append("filename", this.$refs[questionNo].files[0].name);
        formData.append("type", "uploadfile");
        // formData.append("userId", this.userId);
        console.log("formData", formData);
        const auth = {
          headers: {
            "Content-Type": "multipart/form-data",
            // Authorization: `Bearer` + " " + this.checkuser.token,
          },
        };
        console.log("auth", auth);
        const response = await this.axios.post(
          `https://gscm-service.yuzudigital.com/files/formData`,
          formData,
          auth
        );
        if (questionNo == "q_1_1") {
          this.form1.img_url = response.data.data.path;
          this.form1.showImage = response.data.data.path;
        }
        if (questionNo == "q_1_2") {
          this.form1.img_url1 = response.data.data.path;
          this.form1.showImage1 = response.data.data.path;
        }
        if (questionNo == "q_1_3") {
          this.form6.img_url = response.data.data.path;
          this.form6.showImage = response.data.data.path;
        }

        console.log("SUCCESS!!");
        console.log("response", response);
      }
    },

    async submit1() {
      console.log(this.file, "f");
      if (this.$refs.createForm.validate(true)) {
        // if (this.type == "CONTENT") {
        //   await this.submitfileMain(pic);
        //   console.log("pic", pic);
        // }
        this.user = JSON.parse(
          Decode.decode(localStorage.getItem("gscmAdmin"))
        );
        const auth = {
          headers: { Authorization: `Bearer ${this.user.token}` },
        };
        const data = {
          title_th: this.form1.title_th,
          title_en: this.form1.title_en,
          short_desc_th: this.form1.short_desc_th,
          short_desc_en: this.form1.short_desc_en,
          course_highlights_th: this.form1.course_highlights_th,
          course_highlights_en: this.form1.course_highlights_en,
          img_url: this.form1.img_url,
          img_url1: this.form1.img_url1,
          levelId: 12,
        };
        const response = await this.axios.put(
          `${process.env.VUE_APP_API}/courses/${this.courseId}`,
          data,
          auth
        );
        console.log("createBanner", response);
        console.log(response.data.response_status);
        if (response.data.response_status == "SUCCESS") {
          this.$swal.fire({
            icon: "success",
            text: `บันทึกสำเร็จ`,
            showConfirmButton: false,
            timer: 1500,
          });
          this.e1 = 1;
          this.getAllCourse();
        } else {
          this.$swal.fire({
            icon: "error",
            text: response.data.response_description,
            showConfirmButton: false,
            timer: 1500,
          });
        }
      }
    },

    async checkSubjectEmpty1() {
      this.form3.majors.forEach((element, index) => {
        element.subject.forEach((elementSub, indexSub) => {
          if (!elementSub.id) {
            this.form3.majors[index].subject.splice(indexSub, 1);
            if (!elementSub.id) {
              this.form3.majors[index].subject.splice(indexSub, 1);
            }
          }
        });
      });
    },
    async checkSubjectEmpty2() {
      this.form3.typeCourse.forEach((element, index) => {
        element.subject.forEach((elementSub, indexSub) => {
          if (!elementSub.id) {
            this.form3.typeCourse[index].subject.splice(indexSub, 1);
            if (!elementSub.id) {
              this.form3.typeCourse[index].subject.splice(indexSub, 1);
            }
          }
        });
      });
    },

    async submit3() {
      await this.checkSubjectEmpty1();
      await this.checkSubjectEmpty2();

      if (this.$refs.createForm.validate(true)) {
        this.user = JSON.parse(
          Decode.decode(localStorage.getItem("gscmAdmin"))
        );
        const auth = {
          headers: { Authorization: `Bearer ${this.user.token}` },
        };
        const data = {
          ...this.form3,
          courseId: this.courseId,
        };
        const response = await this.axios.put(
          `${process.env.VUE_APP_API}/majors/form3/${this.courseId}`,
          data,
          auth
        );
        console.log("createBanner", response);
        console.log(response.data.response_status);
        if (response.data.response_status == "SUCCESS") {
          this.$swal.fire({
            icon: "success",
            text: `บันทึกสำเร็จ`,
            showConfirmButton: false,
            timer: 1500,
          });
          this.e1 = 3;
          this.getForm3();
        } else {
          this.$swal.fire({
            icon: "error",
            text: response.data.response_description,
            showConfirmButton: false,
            timer: 1500,
          });
        }
      }
    },

    async submit4() {
      if (this.$refs.createForm.validate(true)) {
        this.user = JSON.parse(
          Decode.decode(localStorage.getItem("gscmAdmin"))
        );
        const auth = {
          headers: { Authorization: `Bearer ${this.user.token}` },
        };
        const data = {
          ...this.form4,
          courseId: this.courseId,
        };
        const response = await this.axios.put(
          `${process.env.VUE_APP_API}/courseStructures/form4`,
          data,
          auth
        );
        console.log("createBanner", response);
        console.log(response.data.response_status);
        if (response.data.response_status == "SUCCESS") {
          this.$swal.fire({
            icon: "success",
            text: `บันทึกสำเร็จ`,
            showConfirmButton: false,
            timer: 1500,
          });
          this.e1 = 4;
          this.getForm4();
        } else {
          this.$swal.fire({
            icon: "error",
            text: response.data.response_description,
            showConfirmButton: false,
            timer: 1500,
          });
        }
      }
    },

    async submit5() {
      if (this.$refs.createForm.validate(true)) {
        this.user = JSON.parse(
          Decode.decode(localStorage.getItem("gscmAdmin"))
        );
        const auth = {
          headers: { Authorization: `Bearer ${this.user.token}` },
        };
        const data = {
          courseId: this.courseId,
          ...this.form5,
        };
        const response = await this.axios.put(
          `${process.env.VUE_APP_API}/priceCourses/form5`,
          data,
          auth
        );
        console.log("createBanner", response);
        console.log(response.data.response_status);
        if (response.data.response_status == "SUCCESS") {
          this.$swal.fire({
            icon: "success",
            text: `บันทึกสำเร็จ`,
            showConfirmButton: false,
            timer: 1500,
          });
          this.e1 = 5;
          this.isSubmit5 = !this.isSubmit5;
        } else {
          this.$swal.fire({
            icon: "error",
            text: response.data.response_description,
            showConfirmButton: false,
            timer: 1500,
          });
        }
      }
    },

    async submit6() {
      let pathPic = [];
      pathPic = this.showImageUpload;

      if (this.$refs.createForm.validate(true)) {
        this.user = JSON.parse(
          Decode.decode(localStorage.getItem("gscmAdmin"))
        );
        const auth = {
          headers: { Authorization: `Bearer ${this.user.token}` },
        };
        const data = {
          courseId: this.courseId,
          ...this.form6,
        };
        const response = await this.axios.put(
          `${process.env.VUE_APP_API}/courses/form6`,
          data,
          auth
        );
        console.log("createBanner", response);
        console.log(response.data.response_status);
        if (response.data.response_status == "SUCCESS") {
          this.$swal.fire({
            icon: "success",
            text: `บันทึกสำเร็จ`,
            showConfirmButton: false,
            timer: 1500,
          });
          await this.uploadFiles1(pathPic);
          await this.$router.push("ManageCourse");
        } else {
          this.$swal.fire({
            icon: "error",
            text: response.data.response_description,
            showConfirmButton: false,
            timer: 1500,
          });
        }
      }
    },

    async getAllCourse() {
      const auth = {
        headers: { Authorization: `Bearer ${this.user.token}` },
      };
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/courses?id=${this.courseId}`,
        auth
      );
      console.log("getForm1", response.data.data);
      this.form1 = response.data.data[0];
      this.form1.showImage = this.form1.img_url;
      this.form1.showImage1 = this.form1.img_url1;
    },

    async getTypeCourse() {
      this.loading = true;
      const auth = {
        headers: { Authorization: `Bearer ${this.user.token}` },
      };
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/majors/form3/${this.courseId}`,
        auth
        // 'http://localhost:8081/types/'
      );
      this.itemsTypeCourse = response.data.data.typeCourse;
      console.log("this.itemsTypeCourse", this.itemsTypeCourse);
      this.loading = false;
    },
  },
};
</script>
